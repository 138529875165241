import React from 'react'
import { Link } from 'gatsby'
import { Navbar, Nav } from 'react-bootstrap';

export default () => (
        <Navbar bg='dark' expand='lg' variant='dark'>
          <Link to="/" className="navbar-brand">No Frills News</Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className='ml-auto'>
              <Link to="/av-news" className="nav-link" activeClassName="active">Autonomous Vehicle News</Link>
              <Link to="/truck-news" className="nav-link" activeClassName="active">Truck News</Link>
              <Link to="/connected-car-news" className="nav-link" activeClassName="active">Connected Car</Link>
              <Link to="/image-recognition-news" className="nav-link" activeClassName="active">Image Recognition</Link>

            </Nav>
          </Navbar.Collapse>
        </Navbar>
)
